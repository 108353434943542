<template>
  <b-card
    class="pricefixedruleonlinenormal-edit-wrapper"
  >
    <b-row>
      <b-col md="3" class="ml-2">
        <div class="inner-card-title font-large-1">定价规则1-常规品</div>
      </b-col>
      <b-col md="0">
        <b-button
            variant="primary"
            @click="repeateAgain"
            class="mt-0"
        >
          <feather-icon
              icon="PlusIcon"
          />
          <span>添加</span>
        </b-button>
      </b-col>
    </b-row>
    <!-- form -->
    <b-form id="pricefixedruleonlinenormalForm" class="edit-form mt-2">
      <b-row v-for="(item,index) in pricefixedruleonlinenormal"
             :id="item.id"
             :key="item.id"
             ref="row"
             class="mt-1"
      >
        <b-col md="4">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="成本区间（元）"
              label-for="car_no"
              label-size="sm"
              class="mb-1"
          >
            <b-row>
              <b-col md="5">
                <b-form-input
                    size="sm"
                    type="number"
                    v-model="item.cost_min"
                />
              </b-col>
              ~
              <b-col md="5">
                <b-form-input
                    size="sm"
                    type="number"
                    v-model="item.cost_max"
                />
              </b-col>
            </b-row>
          </b-form-group>
        </b-col>

        <b-col md="3">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="毛利率（%）"
              label-for="car_no"
              label-size="sm"
              class="mb-1"
          >
            <b-form-input
                size="sm"
                type="number"
                v-model="item.gross_profit_rate"
            />
          </b-form-group>
        </b-col>

        <b-col md="3">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="毛利额（元）"
              label-for="car_no"
              label-size="sm"
              class="mb-1"
          >
            <b-form-input
                size="sm"
                type="number"
                v-model="item.gross_profit_amount"
            />
          </b-form-group>
        </b-col>

        <b-col md="2">
          <b-button
              variant="success"
              class="mr-1"
              @click="save(item)"
          >
            <feather-icon
                icon="SaveIcon"
                class="mr-25"
            />
            <span>保存</span>
          </b-button>

          <b-button
              variant="danger"
              class="mr-1"
              @click="removeItem(item.id,index)"
          >
            <feather-icon
                icon="XIcon"
                class="mr-25"
            />
            <span>删除</span>
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BCard, BMedia, BAvatar, BCardText, BForm, BRow, BCol, BFormGroup, BFormInput, BImg, BFormFile, BLink, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {onUnmounted, ref} from '@vue/composition-api'
import router from "@/router";
import store from "@/store";
import pricefixedruleonlinenormalStore from './pricefixedruleonlinenormalStore'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  toTime,
  toDate,
  getCodeOptions,
  getCode,
  getCodeLabel,
  getCodeColor,
  isEmpty,
  isEmptys
} from '@core/utils/filter'

export default {
  components: {
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    useToast,
  },
  methods: {
    repeateAgain() {
      this.pricefixedruleonlinenormal.push({state: 0})
    },
  },
  data() {
    return {
      id: ref(0),
      pricefixedruleonlinenormal: [],
    }
  },
  setup() {
    const toast = useToast()

    // Register module
    if (!store.hasModule('pricefixedruleonlinenormal')) store.registerModule('pricefixedruleonlinenormal', pricefixedruleonlinenormalStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('pricefixedruleonlinenormal')) store.unregisterModule('pricefixedruleonlinenormal')
    })

    const search = function () {
      store.dispatch('pricefixedruleonlinenormal/search').then(res => {
        const data = res.data.data.list
        for (let i = 0; i < data.length; i++) {
          this.pricefixedruleonlinenormal.push(data[i])
        }
      })
    }

    const save = function (params) {
      if (isEmptys(params.cost_min)||isEmptys(params.cost_max)){
        toast.error("请填写成本区间!")
        return false
      }
      if (parseFloat(params.cost_max)<parseFloat(params.cost_min)){
        toast.error("区间错误!")
        return false
      }
      if (params.cost_max<0||params.cost_min<0){
        toast.error("区间错误!")
        return false
      }
      if (isEmptys(params.gross_profit_rate)){
        toast.error("请填写毛利率!")
        return false
      }
      if (isEmptys(params.gross_profit_amount)){
        toast.error("请填写毛利额!")
        return false
      }
      if (params.gross_profit_rate<0){
        toast.error("毛利率不能为负!")
        return false
      }
      if (params.gross_profit_amount<0){
        toast.error("毛利额不能为负!")
        return false
      }
      store.dispatch('pricefixedruleonlinenormal/save', params).then(res => {
        if (res.data.code==0){
          toast.success('数据已保存!')
          params.id = res.data.data.id
        }else {
          toast.error(res.data.data)
        }

      })
    }

    const changeSelect = function (key,params,event) {
      if (event != null) {
        params[key] = event.value
      } else {
        params[key] = null
      }
    }

    const removeItem = function (id,index) {
      if (confirm("确定删除吗?")){
        if (isEmpty(id)){
          this.pricefixedruleonlinenormal.splice(index, 1)
        }else {
          store.dispatch('pricefixedruleonlinenormal/state', {id: id,state:0}).then(res => {
            toast.success("删除成功!")
            this.pricefixedruleonlinenormal.splice(index, 1)
          })
        }
      }
    }

    return {
      save,
      removeItem,
      changeSelect,
      search,

      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      isEmpty,
      isEmptys,
    }
  },
  created() {
    this.search()
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
